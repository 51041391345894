import React from 'react';
import { Card } from 'react-bootstrap';
import './styles.scss';
import { useDispatch, useSelector } from 'react-redux';

import { vaultService } from '../../../core/services/vault-service';
import { changeTab } from '../actions';
import ListBuyer from '../ListBuyer';
import ListOrder from '../ListOrder';
import TemplateList from '../Templates/List';

const PanelWebMaster = () => {
    const { tab } = useSelector((state) => state.webMasterReducer);

    const service = vaultService();

    const storageTab = service.getItem('tabWebMaster');

    const activeTab = storageTab ? storageTab : tab;

    const dispatch = useDispatch();

    const handleChangeTabs = (tab) => {
        dispatch(changeTab(tab));
        service.setItem('tabWebMaster', tab);
    };

    return (
        <>
            <Card className="Buyer">
                <Card.Header>
                    <div className="Buyer-header">
                        <span className={`Buyer-header-title ${activeTab === 1 && 'active'}`} onClick={() => handleChangeTabs(1)}>
                            Список Buyers
                        </span>
                        <span className={`Buyer-header-title ${activeTab === 2 && 'active'}`} onClick={() => handleChangeTabs(2)}>
                            Список заказов
                        </span>
                        <span className={`Buyer-header-title ${activeTab === 3 && 'active'}`} onClick={() => handleChangeTabs(3)}>
                            Список Templates
                        </span>
                    </div>
                </Card.Header>
                <Card.Body>
                    {activeTab === 1 && <ListBuyer />}
                    {activeTab === 2 && <ListOrder />}
                    {activeTab === 3 && <TemplateList />}
                </Card.Body>
            </Card>
        </>
    );
};

export default PanelWebMaster;
