import { Col, Row } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import './styles.scss';
import { useEffect, useState } from 'react';
import { changeOrderPages, deleteOrder, getBuyers, getOrders, updateOrder } from '../actions';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../components/UI/Button';
import { getTopic } from '../../SysAdmin/action';
import ModalAccept from '../../../components/Modals/ModalAccept';
import SelectSearch from '../../../components/UI/Select';
import SelectMulti from '../../../components/UI/SelectMulti';
import UIFormInput from '../../../components/UI/UIFormInput';

const ListOrder = () => {
    const orders = useSelector((state) => state.webMasterReducer.orders);
    const page = useSelector((state) => state.webMasterReducer.pageOrder);
    const topics = useSelector((state) => state.serverReducer.topics);
    const buyers = useSelector((state) => state.webMasterReducer.buyers);

    const [isLoading, setIsLoading] = useState(0);
    const [isShow, setIsShow] = useState(false);
    const [currentId, setCurrentId] = useState(null);
    const [buyer, setBuyer] = useState('');
    const [status, setStatus] = useState('');

    const statusOptions = [
        { label: 'Closed', value: 'closed' },
        { label: 'Open', value: 'open' },
    ];
    const dispatch = useDispatch();

    const changePage = async (page) => {
        const currentPage = page.selected + 1;
        dispatch(changeOrderPages(currentPage));
        await dispatch(getOrders(currentPage));
    };

    const loadData = async () => {
        await dispatch(getOrders(page, buyer, status));
        await dispatch(getTopic());
        await dispatch(getBuyers(1));
    };

    const handleClickOrderDelete = async () => {
        await dispatch(deleteOrder(currentId));
        closeModal();
    };

    const showNameTopic = (id) => {
        const topic = topics.find((item) => item.id === id);
        if (topic) {
            return topic.topic;
        }

        return '-';
    };

    useEffect(() => {
        loadData();
    }, []);

    const handleClickButtonClosed = async (item) => {
        setIsLoading(item.id);
        const options = {
            ...item,
            user_id: item.user?.id,
            status: 'closed',
        };

        await dispatch(updateOrder(item.id, options));
        setIsLoading(0);
    };

    const closeModal = () => {
        setIsShow(false);
        setCurrentId(null);
    };

    const getOptionsBuyers = () => {
        return (buyers?.data || []).map((item) => ({ label: item.name, value: item.id }));
    };

    const handleChangeSelect = async (value, id) => {
        if (id === 'buyer') {
            await setBuyer(value);
            await dispatch(getOrders(page, value, status));
            return;
        }
        if (id === 'status') {
            await setStatus(value);
            await dispatch(getOrders(page, buyer, value));
            return;
        }
    };

    return (
        <div>
            {/*<Row style={{ marginBottom: '10px' }}>*/}
            {/*    <Col xs={3}>*/}
            {/*        <SelectSearch*/}
            {/*            onChange={(e) => handleChangeSelect(e, 'buyer')}*/}
            {/*            title={'Байер'}*/}
            {/*            options={getOptionsBuyers()}*/}
            {/*            itemLabel={'label'}*/}
            {/*            itemValue={'value'}*/}
            {/*            default={buyer}*/}
            {/*            backspaceRemovesValue={false}*/}
            {/*            value={buyer}*/}
            {/*        />*/}
            {/*    </Col>*/}
            {/*    <Col xs={3}>*/}
            {/*        <SelectSearch*/}
            {/*            onChange={(e) => handleChangeSelect(e, 'status')}*/}
            {/*            title={'Статус'}*/}
            {/*            options={statusOptions}*/}
            {/*            backspaceRemovesValue={false}*/}
            {/*            itemLabel={'label'}*/}
            {/*            itemValue={'value'}*/}
            {/*            default={status}*/}
            {/*            value={status}*/}
            {/*        />*/}
            {/*    </Col>*/}
            {/*</Row>*/}

            <div className="ListOrder">
                <div className="ListOrder-filters">
                    <SelectSearch
                        onChange={(e) => handleChangeSelect(e, 'buyer')}
                        placeholder={'Байер'}
                        options={getOptionsBuyers()}
                        itemLabel={'label'}
                        itemValue={'value'}
                        default={buyer}
                        backspaceRemovesValue={false}
                        value={buyer}
                        className="ListOrder-filters-select"
                    />
                    <SelectSearch
                        onChange={(e) => handleChangeSelect(e, 'status')}
                        placeholder={'Статус'}
                        options={statusOptions}
                        backspaceRemovesValue={false}
                        itemLabel={'label'}
                        itemValue={'value'}
                        default={status}
                        value={status}
                        className="ListOrder-filters-select"
                    />
                </div>
                <div className="Table">
                    <div className="Table-head">
                        <div className="Table-head-item">Name</div>
                        <div className="Table-head-item">Buyer</div>
                        <div className="Table-head-item">Comment</div>
                        <div className="Table-head-item">Status</div>
                        <div className="Table-head-item ml-auto  justify-content-end">Actions</div>
                    </div>
                    <div className="Table-body">
                        {orders.data?.length !== 0 ? (
                            orders.data.map((item) => (
                                <div className="Table-body-row" key={item.id}>
                                    <div className="Table-body-row-item">{item.domain_name}</div>
                                    <div className="Table-body-row-item">{item.user?.name}</div>
                                    <div className="Table-body-row-item">{item.desc}</div>
                                    <div className="Table-body-row-item">{item.status}</div>
                                    <div className="Table-body-row-item ml-auto justify-content-end" style={{ width: '100px' }}>
                                        {item?.status !== 'closed' && (
                                            <Button
                                                onClick={() => handleClickButtonClosed(item)}
                                                blue
                                                title="Closed"
                                                isLoading={isLoading === item.id}
                                                className="ListOrder-buttons-button"
                                            />
                                        )}
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="Table-no-content">No data available yet</div>
                        )}
                    </div>
                    <div className="Table-footer">
                        <div className="Table-pagination">
                            {orders.meta?.total > orders.meta?.per_page && (
                                <ReactPaginate
                                    previousLabel="<"
                                    nextLabel=">"
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    breakLabel="..."
                                    breakClassName="page-item"
                                    breakLinkClassName="page-link"
                                    pageCount={orders.meta?.total / orders.meta?.per_page}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={1}
                                    onPageChange={changePage}
                                    containerClassName="pagination"
                                    activeClassName="active"
                                    forcePage={page - 1}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <ModalAccept
                regenerate={handleClickOrderDelete}
                title="Do you want delete this order ?"
                closeModal={closeModal}
                show={isShow}
                isLoading={isLoading}
            />
        </div>
    );
};

export default ListOrder;
