import { useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { loginByEmail } from './actions';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../core/helpers/routes';
import { NotificationContainer } from 'react-notifications';
import { loginRole } from '../../utils/roleLOgin';
import docCookies from '../../core/services/cookie';
import { vaultService } from '../../core/services/vault-service';
import './Login.scss';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorLogin, setErrorLogin] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleChangeEmail = (e) => {
        const value = e.target.value;
        setEmail(value);
    };

    const handleChangePassword = (e) => {
        const value = e.target.value;
        setPassword(value);
    };

    const service = vaultService();

    const logOut = () => {
        docCookies.removeItem('token');
        service.removeItems('role');
        service.removeItems('user');
    };

    const submitClickHandler = async () => {
        setIsLoading(true);
        const resp = await dispatch(loginByEmail(email, password));
        if (typeof resp.data?.message === 'string') {
            setEmail('');
            setPassword('');
            logOut();
            setIsLoading(false);
            return;
        }
        if (typeof resp.data === 'string') {
            setEmail('');
            setPassword('');
            logOut();
            setIsLoading(false);
            return;
        }

        if (resp?.response?.status === 422) {
            setErrorLogin(true);
            setTimeout(() => {
                setErrorLogin(false);
            }, 3000);
        }

        if (resp?.data?.access_token) {
            const role = loginRole(resp?.data?.user?.roles[resp?.data?.user?.roles.length - 1]);
            if (role === 'error') {
                setEmail('');
                setPassword('');
                logOut();
                setIsLoading(false);
                return;
            }
            navigate(ROUTES.index.main);
            window.location.reload();
        }
        setIsLoading(false);
    };

    const clickRegister = () => {
        navigate(ROUTES.register.link);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            submitClickHandler();
        }
    };

    return (
        <div className="Login">
            {/*<div style={{ height: '100vh' }} className="d-flex flex-column justify-content-center align-items-center">*/}
            <div className="Login-form">
                <div className="Login-form-title">Sign In</div>
                <div className="Login-form-border" />
                <div className="d-flex flex-column gap-4 align-items-center justify-content-center">
                    <input
                        className="form-control"
                        type="text"
                        placeholder="Email"
                        value={email}
                        style={{ height: '50px' }}
                        onChange={handleChangeEmail}
                        onKeyDown={handleKeyDown}
                    />
                    <input
                        className="form-control"
                        type="password"
                        placeholder="Password"
                        style={{ height: '50px' }}
                        value={password}
                        onChange={handleChangePassword}
                        onKeyDown={handleKeyDown}
                    />
                </div>

                {errorLogin && (
                    <div className="d-flex text-danger">
                        <span>Не правильный логин или пароль</span>
                    </div>
                )}
                <Button className="Login-button" variant="primary" onClick={submitClickHandler} disabled={isLoading}>
                    {isLoading ? <Spinner size="sm" animation="border" /> : 'Sign In'}
                </Button>
                <div className="Login-form-border" />

                <div className="Login-form-footer">
                    Don’t have an account yet?
                    <a onClick={clickRegister}> Sign Up</a>
                </div>
            </div>

            <NotificationContainer />
        </div>
    );
};

export default Login;
