import { NotificationManager } from 'react-notifications';
import { helperError } from '../../core/helpers/helperError';
import { API } from '../../core/api';
import axios from 'axios';
import { DELETE_TEMPLATE, SET_TEMPLATE } from '../Bayer/types';

export const putOutCloak = (domainId, options) => async () => {
    try {
        const response = await API.put(`microservices/filtering/api/v1/filtering/domain/${domainId}/cloak/rules`, options);
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const getBlocks = (domainId) => async () => {
    try {
        const response = await API.get(`microservices/filtering/api/v1/filtering/domain/${domainId}/blocks/rules`);
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const editBlocks = (options, domainId) => async () => {
    try {
        const response = await API.put(`microservices/filtering/api/v1/filtering/domain/${domainId}/blocks/rules`, options);

        NotificationManager.success(`Success`, '', 3500);
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const getOutCloak = (domainId) => async () => {
    try {
        const response = await API.get(`microservices/filtering/api/v1/filtering/domain/${domainId}/cloak/rules`);
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const getLandings = (domainId) => async () => {
    try {
        const response = await API.get(`microservices/filtering/api/v1/filtering/domain/${domainId}/defaults`);
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const saveLandings = (options, domainId) => async () => {
    try {
        const response = await API.put(`microservices/filtering/api/v1/filtering/domain/${domainId}/defaults`, options);
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const getFilters = (domainId) => async () => {
    try {
        const response = await axios.get(`http://95.216.4.23:8442/api/v1/filtering/domain/${domainId}/pages`);
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const getTemplatesByUserId =
    (userId, search = '') =>
    async (dispatch) => {
        try {
            const response = await axios.get(
                `http://95.216.4.23:8442/api/v1/filtering/templates/account/${userId}/all?search_name=${search}`,
            );
            dispatch({ type: SET_TEMPLATE, payload: response.data });
            return response.data;
        } catch (e) {
            helperError(e);
        }
    };

export const getTemplateByUserAndTemplateId = (userId, templateId) => async () => {
    try {
        const response = await axios.get(
            `http://95.216.4.23:8442/api/v1/filtering/templates/account/${userId}/${templateId}/template`,
        );
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const getTemplateById = (templateId) => async () => {
    try {
        const response = await axios.get(`http://95.216.4.23:8442/api/v1/filtering/templates/${templateId}/template`);
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const deleteTemplateByUserId = (userId, templateId) => async (dispatch) => {
    try {
        const response = await axios.delete(`http://95.216.4.23:8442/api/v1/filtering/templates/account/${userId}/${templateId}
`);
        dispatch({ type: DELETE_TEMPLATE, payload: templateId });
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const deleteTemplate = (templateId) => async (dispatch) => {
    try {
        const response = await axios.delete(`http://95.216.4.23:8442/api/v1/filtering/templates/${templateId}`);
        dispatch({ type: DELETE_TEMPLATE, payload: templateId });
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const getTemplates = (search) => async (dispatch) => {
    try {
        const response = await axios.get(`http://95.216.4.23:8442/api/v1/filtering/templates/all?search_name=${search}`);
        dispatch({ type: SET_TEMPLATE, payload: response.data });
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const getDefaultRule = () => async () => {
    try {
        const response = await API.get(`microservices/filtering/api/v1/filtering/rules/default`);
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const updateDefaultRule = (options) => async () => {
    try {
        const response = await API.put(`microservices/filtering/api/v1/filtering/rules/default`, options);
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const getHeaders = (key) => async () => {
    try {
        const response = await API.get(`microservices/filtering/api/v1/static/get/${key}`);
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const getStaticLanding = (domain) => async () => {
    try {
        const response = await API.get(`microservices/filtering/api/v1/filtering/domain/${domain}/result/enum`);
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const getStaticLandingTemplate = () => async () => {
    try {
        const response = await axios.get(`http://95.216.4.23:8442/api/v1/result/enum`);
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const createTemplateGeneral = (options) => async () => {
    try {
        const response = await axios.post(`http://95.216.4.23:8442/api/v1/filtering/templates`, options);
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const editTemplateGeneral = (options, templateId) => async () => {
    try {
        const response = await axios.put(`http://95.216.4.23:8442/api/v1/filtering/templates/${templateId}`, options);
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const createTemplate = (options, userId) => async () => {
    try {
        const response = await axios.post(`http://95.216.4.23:8442/api/v1/filtering/templates/account/${userId}`, options);
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const editTemplate = (options, userId, templateId) => async () => {
    try {
        const response = await axios.put(
            `http://95.216.4.23:8442/api/v1/filtering/templates/account/${userId}/${templateId}`,
            options,
        );
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const editFilterRules = (options, domainId, pageId) => async () => {
    try {
        const response = await API.put(
            `http://95.216.4.23:8442/api/v1/filtering/domain/${domainId}/pages/${pageId}/rules`,
            options,
        );

        NotificationManager.success(`Success`, '', 3500);
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const deletePage = (domainId, pageId) => async () => {
    try {
        const response = await API.delete(`http://95.216.4.23:8442/api/v1/filtering/domain/${domainId}/pages/${pageId}`);
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const createPage = (domainId, options) => async () => {
    try {
        const response = await API.post(`http://95.216.4.23:8442/api/v1/filtering/domain/${domainId}/pages`, options);
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const editPage = (domainId, pageId, options) => async () => {
    try {
        const response = await API.put(`http://95.216.4.23:8442/api/v1/filtering/domain/${domainId}/pages/${pageId}`, options);
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const getFiltersList = () => async () => {
    try {
        const response = await API.get(`microservices/filtering/api/v1/filters/filters/list`);
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const getGroupLanding = () => async () => {
    try {
        const response = await API.get(`microservices/filtering/api/v1/binom/1/send/public/api/v1/groups/landings`);
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const createNewLand = (options) => async () => {
    try {
        const response = await API.post(
            `microservices/filtering/api/v1/binom/1/send/public/api/v1/landing/not_integrated`,
            options,
        );
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const createNewOffer = (options) => async () => {
    try {
        const response = await API.post(`microservices/filtering/api/v1/binom/1/send/public/api/v1/offer`, options);
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const getSystemTokens = () => async () => {
    try {
        const response = await API.get(`microservices/filtering/api/v1/binom/1/send/public/api/v1/system/tokens`);
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const getLanguageList = () => async () => {
    try {
        const response = await API.get(`microservices/filtering/api/v1/binom/1/send/public/api/v1/language/list`);
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const getCountryList = () => async () => {
    try {
        const response = await API.get(`microservices/filtering/api/v1/binom/1/send/public/api/v1/country/list`);
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const getAffList = () => async () => {
    try {
        const response = await API.get(`microservices/filtering/api/v1/binom/1/send/public/api/v1/affiliate_network/list/all`);
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const getCurrencyList = () => async () => {
    try {
        const response = await API.get(`microservices/filtering/api/v1/binom/1/send/public/api/v1/currency/list`);
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const getLander = () => async () => {
    try {
        const response = await API.get(
            `microservices/filtering/api/v1/binom/1/send/public/api/v1/landing/list/filtered??limit=50&offset=0&sortColumn=id&sortType=DESC`,
        );
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const getOffer = () => async () => {
    try {
        const response = await API.get(
            `microservices/filtering/api/v1/binom/1/send/public/api/v1/offer/alternative/all?limit=50&offset=0&sortColumn=id&sortType=DESC`,
        );
        return response.data;
    } catch (e) {
        helperError(e);
    }
};
