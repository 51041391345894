import { Col, Row } from 'react-bootstrap';
import './styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { changePages, getBuyers } from '../actions';
import { useEffect } from 'react';
import Button from '../../../components/UI/Button';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../core/helpers/routes';

const ListBuyer = () => {
    const buyers = useSelector((state) => state.webMasterReducer.buyers);
    const page = useSelector((state) => state.webMasterReducer.page);

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const changePage = async (page) => {
        const currentPage = page.selected + 1;
        dispatch(changePages(currentPage));
        await dispatch(getBuyers(currentPage));
    };

    const loadData = async () => {
        dispatch(getBuyers(page));
    };

    useEffect(() => {
        loadData();
    }, []);

    const handleClickButton = (id) => {
        const link = ROUTES.listDomainBuyer.link.replace(':userId', id);
        navigate(link);
    };

    return (
        <div className="MyDomain">
            <div className="Table">
                <div className="Table-head">
                    <div className="Table-head-item">Name</div>
                    <div className="Table-head-item ml-auto  justify-content-end">Actions</div>
                </div>
                <div className="Table-body">
                    {buyers.data?.length !== 0 ? (
                        buyers.data.map((item) => (
                            <div className="Table-body-row" key={item.id}>
                                <div className="Table-body-row-item">{item.name}</div>
                                <div className="Table-body-row-item ml-auto justify-content-end" style={{ width: '100px' }}>
                                    <Button onClick={() => handleClickButton(item.id)} blue title="Открыть" />
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="Table-no-content">No data available yet</div>
                    )}
                </div>
                <div className="Table-footer">
                    <div className="Table-pagination">
                        {buyers.meta?.total > buyers.meta?.per_page && (
                            <ReactPaginate
                                previousLabel="<"
                                nextLabel=">"
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                pageCount={buyers.meta?.total / buyers.meta?.per_page}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={1}
                                onPageChange={changePage}
                                containerClassName="pagination"
                                activeClassName="active"
                                forcePage={page - 1}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ListBuyer;
