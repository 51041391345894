import React, { useEffect, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import './styles.scss';
import ListDomain from '../ListDomain';
import MyDomain from '../MyDomain';
import { useDispatch, useSelector } from 'react-redux';
import { changeTab, downloadRdp } from '../actions';
import { getDomainCheck } from '../../SysAdmin/action';
import { vaultService } from '../../../core/services/vault-service';
import GeneratorsWhitePage from '../../../components/GeneratorsWhitePage';
import ListOrder from '../ListOrders';
import ModalStats from '../../../components/Modals/ModalStats';
import ListStatsDomain from '../ListStatsDomain';

const PanelBayer = () => {
    const { tab } = useSelector((state) => state.buyerReducer);
    const [checks, setChecks] = useState([]);
    const [isShowOrder, setIsShowOrder] = useState(false);
    const [isShowStats, setIsShowStats] = useState(false);

    const service = vaultService();

    const storageTab = service.getItem('tabBuyer');

    const activeTab = storageTab ? storageTab : tab;

    const dispatch = useDispatch();

    const handleChangeTabs = (tab) => {
        dispatch(changeTab(tab));
        service.setItem('tabBuyer', tab);
    };

    const closeOpenModalOrder = () => {
        setIsShowOrder(!isShowOrder);
    };

    const closeOpenModalStats = () => {
        setIsShowStats(!isShowStats);
    };

    const loadData = async () => {
        const check = await dispatch(getDomainCheck());
        setChecks(check);
    };

    const showStatusDomain = (domain) => {
        const check = checks.find((item) => item.name === domain.name);

        switch (check?.status) {
            case 'up':
                return <div className="status-up-buyer" />;
            case 'down':
                return <div className="status-down-buyer" />;
            case 'unknown':
                return <div className="status-unknown-buyer" />;
            default:
                return <div className="status-none-buyer" />;
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    const download = async () => {
        const resp = await dispatch(downloadRdp());
        if (resp?.data) {
            const blob = new Blob([resp.data], { type: 'application/rdp' });
            const url = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = `RDP.rdp`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        }
    };

    return (
        <>
            <Card className="Buyer">
                <Card.Header className="border-0">
                    <div className="Buyer-header">
                        <span className={`Buyer-header-title ${activeTab === 1 && 'active'}`} onClick={() => handleChangeTabs(1)}>
                            Список доменов
                        </span>
                        <span className={`Buyer-header-title ${activeTab === 2 && 'active'}`} onClick={() => handleChangeTabs(2)}>
                            Мои домены
                        </span>
                        <span className={`Buyer-header-title ${activeTab === 3 && 'active'}`} onClick={() => handleChangeTabs(3)}>
                            Генераторы White Page
                        </span>
                        <span className={`Buyer-header-title ${activeTab === 4 && 'active'}`} onClick={() => handleChangeTabs(4)}>
                            Список Заказов
                        </span>
                        <span className={`Buyer-header-title ${activeTab === 5 && 'active'}`} onClick={() => handleChangeTabs(5)}>
                            Статистика
                        </span>
                        {activeTab === 2 && (
                            <div className="Buyer-header-buttons">
                                <Button onClick={closeOpenModalOrder}>Заказ домена</Button>
                            </div>
                        )}
                        {activeTab === 5 && (
                            <div className="Buyer-header-buttons">
                                <Button onClick={closeOpenModalStats}>Добавить домен</Button>
                            </div>
                        )}
                        {activeTab === 3 && (
                            <div className="Buyer-header-buttons">
                                <Button onClick={download}>Скачать RDP</Button>
                            </div>
                        )}
                    </div>
                </Card.Header>
                <Card.Body>
                    {activeTab === 1 && <ListDomain showStatusDomain={showStatusDomain} />}
                    {activeTab === 2 && (
                        <MyDomain
                            showStatusDomain={showStatusDomain}
                            closeOpenModalOrder={closeOpenModalOrder}
                            isShowOrder={isShowOrder}
                        />
                    )}

                    {activeTab === 3 && <GeneratorsWhitePage />}
                    {activeTab === 4 && <ListOrder />}
                    {activeTab === 5 && <ListStatsDomain />}
                </Card.Body>
                {isShowStats && <ModalStats show={isShowStats} closeModal={closeOpenModalStats} />}
            </Card>
        </>
    );
};

export default PanelBayer;
