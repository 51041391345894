import { Button, Modal } from 'react-bootstrap';
import './styles.scss';
import { useDispatch } from 'react-redux';
import { ReactComponent as Add } from '../../../assets/images/add.svg';
import { ReactComponent as Question } from '../../../assets/images/question.svg';
import { useEffect, useState } from 'react';
import { getDefaultRule, getFiltersList, getHeaders, updateDefaultRule } from '../../../pages/Filters/actions';
import SelectMini from '../../UI/SelectMini';
import SelectSearchMulti from '../../UI/SelectIsMulti';
import { Tooltip } from 'react-tooltip';
import { infoOperation } from '../../../helpers/data';

const ModalAddedCriterion = ({ closeModal, show, addedCriterion, editRule, defaultRule }) => {
    const [filters, setFilters] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState(null);
    const [values, setValues] = useState('');
    const [numbers, setNumbers] = useState(0);
    const [selectedHeaders, setSelectedHeaders] = useState([]);
    const [optionsHeaders, setOptionsHeaders] = useState([]);
    const [arrayValues, setArrayValues] = useState(['']);
    const [bool, setBool] = useState(1);
    const [date, setDate] = useState('');
    const [arrayKeyValues, setArrayKeyValues] = useState([{ key: '', value: '' }]);
    const [selectedOperators, setSelectedOperators] = useState(null);
    const dispatch = useDispatch();

    const isEqual = selectedFilter?.operators.find((item) => item.name === 'Equal');

    const handleChangeBool = () => {
        const value = bool === 1 ? 0 : 1;

        setBool(value);
    };

    const handleDateTimeChange = (e) => {
        setDate(e.target.value);
    };

    const getDefaultSettings = async (items) => {
        if (defaultRule) {
            const resp = await dispatch(getDefaultRule());
            if (resp.data) {
                const element = resp.data.filters[0];
                if (element) {
                    const filter = items.find((item) => item.id === element.filter.id);
                    if (filter) {
                        const operator = filter.operators.find((item) => item.id === element.operator.id);
                        setSelectedFilter(filter);
                        setSelectedOperators(operator);
                        const dataTypeFilter = filter.data_type;

                        const dataTypeOperators = operator.data_types[dataTypeFilter].join(',');

                        const arrayKeys = element.value.map((obj) => {
                            const key = Object.keys(obj)[0];
                            const value = obj[key];
                            return { key, value };
                        });

                        if (filter?.label === 'Headers') {
                            const headersValues = arrayKeys.map((item) => item.key);
                            setSelectedHeaders(headersValues);
                        }

                        switch (dataTypeOperators) {
                            case 'string':
                                setValues(element.value);
                                break;
                            case 'number':
                                setNumbers(element.value);
                                break;
                            case 'string,array':
                                setArrayValues(element.value);
                                break;
                            case 'assoc':
                                setArrayKeyValues(arrayKeys);
                                break;
                            case 'bool':
                                setBool(Number(element.value));
                                break;
                            case 'datetime':
                                setDate(element.value);
                                break;
                            default:
                                break;
                        }
                    }
                }
            }
        }
    };

    const loadData = async () => {
        const resp = await dispatch(getFiltersList());
        if (resp.data) {
            const items = resp.data.map((item) => ({
                ...item,
                label: item.name,
                value: item.id,
            }));
            setFilters(items);
            if (defaultRule) {
                getDefaultSettings(items);
                return;
            }
            if (editRule) {
                const filter = items.find((item) => item.id === editRule.filter.id);
                if (filter) {
                    const operator = filter.operators.find((item) => item.id === editRule.operator.id);
                    setSelectedFilter(filter);
                    setSelectedOperators(operator);
                    const dataTypeFilter = filter.data_type;

                    const dataTypeOperators = operator.data_types[dataTypeFilter].join(',');

                    if (filter?.label === 'Headers') {
                        const arrayKeys = editRule.value?.map((obj) => {
                            const key = Object.keys(obj)[0];
                            const value = obj[key];
                            return { key, value };
                        });

                        const headersValues = arrayKeys.map((item) => item.key);
                        setSelectedHeaders(headersValues);
                    }

                    switch (dataTypeOperators) {
                        case 'string':
                            setValues(editRule.value);
                            break;
                        case 'number':
                            setNumbers(editRule.value);
                            break;
                        case 'string,array':
                            setArrayValues(editRule.value);
                            break;
                        case 'assoc':
                            setArrayKeyValues(
                                editRule.value?.map((obj) => {
                                    const key = Object.keys(obj)[0];
                                    const value = obj[key];
                                    return { key, value };
                                }),
                            );
                        case 'bool':
                            setBool(Number(editRule.value));
                        case 'datetime':
                            setDate(editRule.value);
                        default:
                            break;
                    }
                }
            }
            return;
        }
    };

    const handleChangeOperators = (operator) => {
        setSelectedOperators(operator);
        setValues('');
        setArrayKeyValues([{ key: '', value: '' }]);
        setArrayValues([]);
    };

    const handleChangeFilter = (filter) => {
        setSelectedHeaders([]);
        setSelectedOperators(null);
        setValues('');
        setArrayKeyValues([{ key: '', value: '' }]);
        setArrayValues([]);
        setSelectedFilter(filter);

        if (filter?.label === 'Headers') {
            const operator = filter?.operators?.find((item) => item.name === 'Not enters');
            if (operator) {
                setSelectedOperators(operator);
            }
        }

        if (filter?.label?.includes('Unique')) {
            const operator = filter?.operators?.find((item) => item.name === 'Equal');
            if (operator) {
                setSelectedOperators(operator);
            }

            setBool(1);
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    const handleChangeValues = (e) => {
        setValues(e.target.value);
    };

    const handleChangeNumber = (e) => {
        const { value } = e.target;
        if (value < 0) return;
        setNumbers(value);
    };

    const closedModal = () => {
        closeModal();
        setSelectedFilter(null);
        setValues('');
        setArrayKeyValues([{ key: '', value: '' }]);
        setArrayValues(['']);
        setSelectedOperators(null);
    };

    const handleClickDeleteKeyValues = (indexValue) => {
        const filtered = arrayKeyValues.filter((item, index) => index !== indexValue);
        setArrayKeyValues(filtered);
    };

    const handleClickAddedNewValues = () => {
        setArrayValues([...arrayValues, '']);
    };

    const handleClickAddedKeywValues = () => {
        setArrayKeyValues([...arrayKeyValues, { key: '', value: '' }]);
    };

    const handleClickDeleteNewValues = (indexValue) => {
        const filtered = arrayValues.filter((item, index) => index !== indexValue);
        setArrayValues(filtered);
    };

    const handleChangeArrayValues = (e, indexValues) => {
        const mapped = arrayValues.map((item, index) => {
            if (index === indexValues) {
                return e.target.value;
            }
            return item;
        });
        setArrayValues(mapped);
    };

    const handleChangeArrayKeyValues = (e, indexValues) => {
        const key = e.target.id;
        const mapped = arrayKeyValues.map((item, index) => {
            if (index === indexValues) {
                return { ...item, [key]: e.target.value };
            }
            return item;
        });
        setArrayKeyValues(mapped);
    };

    const factoryValuesInCriteria = () => {
        switch (factoryDataType()) {
            case 'string':
                return values;
            case 'number':
                return numbers;
            case 'string-array':
                return arrayValues;
            case 'assoc-array':
                return arrayKeyValues.map((item) => ({ [item.key]: item.value }));
            case 'bool':
                return bool;
            case 'datetime':
                return date;

            default:
                return null;
        }
    };

    const addedNewCriteria = async () => {
        const item = {
            filter: { name: selectedFilter.name, id: selectedFilter.id },
            operator: { name: selectedOperators.name, id: selectedOperators.id },
            active: 1,
            value: factoryValuesInCriteria(),
        };

        if (defaultRule) {
            const filter = {
                active: item.active,
                get_data_method_id: item.filter.id,
                operator_method_id: item.operator.id,
                value: item.value,
                position: 1,
            };
            const options = {
                filters: [filter],
            };

            await dispatch(updateDefaultRule(options));
            closedModal();

            return;
        }

        if (editRule) {
            item.id = editRule.id;
        }

        addedCriterion(item);
        closedModal();
    };

    const factoryDataType = () => {
        if (selectedFilter) {
            const dataTypeFilter = selectedFilter.data_type;

            if (selectedOperators) {
                const dataTypeOperators = selectedOperators.data_types[dataTypeFilter].join(',');

                switch (dataTypeOperators) {
                    case 'string':
                        return 'string';
                    case 'string,array':
                        return 'string-array';
                    case 'assoc':
                        return 'assoc-array';
                    case 'bool':
                        return 'bool';
                    case 'datetime':
                        return 'datetime';
                    case 'number':
                        return 'number';
                    default:
                        return 'none';
                }
            }
            return 'none';
        }
        return 'none';
    };

    const factoryDataTypeDisable = () => {
        if (selectedFilter) {
            const dataTypeFilter = selectedFilter.data_type;

            if (selectedOperators) {
                const dataTypeOperators = selectedOperators.data_types[dataTypeFilter].join(',');

                switch (dataTypeOperators) {
                    case 'string':
                        return false;
                    case 'number':
                        return false;

                    case 'string,array':
                        const find = arrayValues.findIndex((item) => item.length === 0);
                        if (find !== -1) return true;
                        if (arrayValues.length === 0) return true;
                        return false;

                    case 'assoc':
                        const finded = arrayKeyValues.find((item) => item.key.length === 0);
                        if (finded) return true;
                        if (arrayKeyValues.length === 0) return true;
                        return false;
                    case 'datetime':
                        if (date.length === 0) return true;
                        return false;
                    default:
                        return false;
                }
            }
            return true;
        }
        return true;
    };

    const isDisableButton = () => {
        if (typeof selectedFilter === 'null') return true;
        if (typeof selectedOperators === 'null') return true;
        if (factoryDataTypeDisable()) return true;
        return false;
    };

    const handleChangeSelectHeaders = (e) => {
        const array = e.map((item) => ({ key: item, value: null }));
        setArrayKeyValues(array);
        setSelectedHeaders(e);
    };

    const handleChangeSelectStringArray = (e) => {
        setArrayValues(e);
    };

    useEffect(() => {
        if (selectedFilter && selectedFilter?.label === 'Headers') {
            loadHeadersOptions(selectedFilter?.static_key);
        }
        if (selectedFilter && selectedFilter?.data_type === 'string' && isEqual) {
            loadKeyOptions(selectedFilter?.static_key);
        }
    }, [selectedFilter]);

    const loadHeadersOptions = async (key) => {
        const resp = await dispatch(getHeaders(key));
        const options = resp.map((item) => ({ label: item.header_name, value: item.header_name }));
        setOptionsHeaders(options);
    };

    const loadKeyOptions = async (key) => {
        const resp = await dispatch(getHeaders(key));
        const options = resp.map((item) => ({ label: item, value: item }));
        setOptionsHeaders(options);
    };

    const showFactoryContentWithDataType = () => {
        if (selectedFilter && selectedFilter?.data_type === 'string' && isEqual && selectedOperators) {
            return (
                <div className="Criteria-values">
                    <SelectSearchMulti
                        onChange={handleChangeSelectStringArray}
                        options={optionsHeaders}
                        itemLabel={'label'}
                        itemValue={'value'}
                        values={arrayValues}
                        default={arrayValues}
                        selectedNewValue
                    />
                </div>
            );
        }

        if (selectedFilter && selectedFilter?.label === 'Headers') {
            return (
                <div className="Criteria-values">
                    <SelectSearchMulti
                        onChange={handleChangeSelectHeaders}
                        options={optionsHeaders}
                        itemLabel={'label'}
                        itemValue={'value'}
                        values={selectedHeaders}
                        default={selectedHeaders}
                        selectedNewValue
                    />
                </div>
            );
        }

        switch (factoryDataType()) {
            case 'string':
                return (
                    <div className="Criteria-values">
                        <input type="text" onChange={handleChangeValues} value={values} />
                    </div>
                );

            case 'number':
                return (
                    <div className="Criteria-values">
                        <input type="number" onChange={handleChangeNumber} value={numbers} />
                    </div>
                );

            case 'string-array':
                return (
                    <div className="Criteria-array-string">
                        {arrayValues.map((item, index) => (
                            <div className="Criteria-values" style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                                <input type="text" onChange={(e) => handleChangeArrayValues(e, index)} value={item} />
                                <button
                                    onClick={() => handleClickDeleteNewValues(index)}
                                    className="wrapper-button-added-path-button"
                                    style={{ height: '100%' }}
                                >
                                    <span>Delete</span>
                                </button>
                            </div>
                        ))}
                        <div className="wrapper-button-added-path">
                            <button onClick={handleClickAddedNewValues} className="wrapper-button-added-path-button">
                                <span>Added</span>
                                <Add />
                            </button>
                        </div>
                    </div>
                );

            case 'datetime':
                return (
                    <div className="Criteria-date">
                        <input
                            type="datetime-local"
                            id="datetime"
                            name="datetime"
                            className="date-picker"
                            value={date}
                            onChange={handleDateTimeChange}
                        />
                    </div>
                );

            case 'bool':
                return (
                    <div className="Criteria-bool">
                        <div className="Rules-container-content-item-active">
                            <div className="form-check form-switch">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    checked={bool === 1 ? true : false}
                                    onChange={handleChangeBool}
                                    disabled={selectedFilter?.label?.includes('Unique')}
                                />
                            </div>
                        </div>
                    </div>
                );

            case 'assoc-array':
                return (
                    <div className="Criteria-array-string">
                        {arrayKeyValues.map((item, index) => (
                            <div className="Criteria-values" style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                                <input
                                    type="text"
                                    onChange={(e) => handleChangeArrayKeyValues(e, index)}
                                    value={item.key}
                                    id="key"
                                />
                                <input
                                    type="text"
                                    onChange={(e) => handleChangeArrayKeyValues(e, index)}
                                    value={item.value}
                                    id="value"
                                />
                                <button
                                    onClick={() => handleClickDeleteKeyValues(index)}
                                    className="wrapper-button-added-path-button"
                                    style={{ height: '100%' }}
                                >
                                    <span>Delete</span>
                                </button>
                            </div>
                        ))}
                        <div className="wrapper-button-added-path">
                            <button onClick={handleClickAddedKeywValues} className="wrapper-button-added-path-button">
                                <span>Added</span>
                                <Add />
                            </button>
                        </div>
                    </div>
                );

            default:
                return null;
        }
    };

    const factoryTitle = () => {
        if (editRule) return 'Edit Filter';
        if (defaultRule) return 'Change Default';
        return 'Create Filter';
    };

    const showInfoFilter = () => {
        return (
            <div className="info_norm_buyer">
                <Question className="question-icon" data-tooltip-id="question-tooltip-filter" />
                <Tooltip
                    id="question-tooltip-filter"
                    place="bottom"
                    style={{
                        zIndex: '9999999999',
                        margin: 0,
                        padding: '10px',
                        width: '350px',
                    }}
                >
                    <div className="info_norm_buyer-content">
                        {selectedFilter && <p className="m-0 p-0 w-100">{selectedFilter?.info}</p>}
                    </div>
                </Tooltip>
            </div>
        );
    };

    const showInfoOperation = () => {
        const renderInfoOperation = () => {
            if (!selectedFilter) return null;

            const { operators } = selectedFilter;
            const conditions = [
                { condition: operators.some((item) => item.name.includes('Enters')), content: infoOperation.enter },
                {
                    condition:
                        operators.some((item) => item.name.includes('More')) ||
                        operators.some((item) => item.name.includes('Less')),
                    content: infoOperation.more,
                },
                { condition: operators.some((item) => item.name.includes('Equal')), content: infoOperation.equal },
            ];

            return conditions.map(
                ({ condition, content }, index) =>
                    condition && (
                        <p key={index} className="m-0 mb-2 p-0 w-100">
                            {content}
                        </p>
                    ),
            );
        };

        return (
            <div className="info_norm_buyer ml-2">
                <Question className="question-icon" data-tooltip-id="question-tooltip-operation" />
                <Tooltip
                    id="question-tooltip-operation"
                    place="bottom"
                    style={{
                        zIndex: '9999999999',
                        margin: 0,
                        padding: '10px',
                        width: '350px',
                    }}
                >
                    <div className="info_norm_buyer-content" style={{ whiteSpace: 'wrap' }}>
                        {renderInfoOperation()}
                        <p className="m-0 p-0 w-100">{infoOperation.note}</p>
                    </div>
                </Tooltip>
            </div>
        );
    };

    return (
        <Modal show={show} onHide={closedModal} className="modal-wrapperr">
            <Modal.Header closeButton id="test-modal-header">
                <Modal.Title>{factoryTitle()}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="Criteria">
                    <div className="Criteria-selected">
                        <div className="Criteria-selected-select">
                            <SelectMini options={filters} onChange={handleChangeFilter} value={selectedFilter} />
                            {selectedFilter && showInfoFilter()}
                        </div>
                        <div className="Criteria-selected-options">
                            <div className="Criteria-selected-options-field">
                                {selectedFilter
                                    ? selectedFilter.operators.map((item) => (
                                          <div
                                              key={item.id}
                                              className={
                                                  selectedOperators?.id === item?.id
                                                      ? 'Criteria-selected-options-field-item-active'
                                                      : 'Criteria-selected-options-field-item'
                                              }
                                              onClick={() => handleChangeOperators(item)}
                                          >
                                              {item.name}
                                          </div>
                                      ))
                                    : null}
                                {selectedFilter && showInfoOperation()}
                            </div>
                        </div>
                    </div>
                    {showFactoryContentWithDataType()}
                    <div className="Criteria-buttons">
                        <Button
                            className="wrapper-button-added-path-button"
                            disabled={isDisableButton()}
                            onClick={addedNewCriteria}
                        >
                            Apply
                        </Button>
                        <Button
                            style={{ background: 'silver' }}
                            className="wrapper-button-added-path-button"
                            variant="secondary"
                            onClick={closedModal}
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ModalAddedCriterion;
