import { Col, Row } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import './styles.scss';
import ReactPaginate from 'react-paginate';
import UICardContainer from '../../../components/UI/UICardContainer';
import Button from '../../../components/UI/Button';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../core/helpers/routes';
import { useDispatch, useSelector } from 'react-redux';
import { changePages, getServers } from '../action';
import moment from 'moment';
import ModalCreateServer from '../../../components/Modals/ModalCreateServer';
import ModalCreateGeneralDomain from '../../../components/Modals/ModalCreateGeneralDomain';
import ModalCreatePreset from '../../../components/Modals/ModalCreatePreset';

const PanelSysAdmin = () => {
    const servers = useSelector((state) => state.serverReducer.servers);
    const page = useSelector((state) => state.serverReducer.page);
    const [isShow, setIsShow] = useState(false);
    const [isShowPreset, setIsShowPreset] = useState(false);
    const [isShowGeneral, setIsShowGeneral] = useState(false);

    const handleOpenAggregator = () => {
        navigate('/mail-aggregator');
    };

    const closeOpenModal = () => {
        setIsShow(!isShow);
    };

    const closeOpenModalGeneral = () => {
        setIsShowGeneral(!isShowGeneral);
    };

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const loadData = async () => {
        await dispatch(getServers(page));
    };

    useEffect(() => {
        loadData();
    }, []);

    const changePage = async (page) => {
        const currentPage = page.selected + 1;
        dispatch(changePages(currentPage));
        await dispatch(getServers(currentPage));
    };

    const handleClickMore = (id) => {
        navigate(ROUTES.domeins.path.replace(':id', id));
    };

    const formatDate = (inputDate) => {
        const date = moment(inputDate);
        return date.format('DD.MM.YYYY');
    };

    const closeModalPreset = () => {
        setIsShowPreset(false);
    };

    const openModalPreset = () => {
        setIsShowPreset(true);
    };

    return (
        <UICardContainer
            title="Панель системного администратора"
            titleActionButton="Добавить Server"
            action={closeOpenModal}
            titleActionButtonTwo="Добавить General Domain"
            actionTwo={closeOpenModalGeneral}
            titleActionButtonThree="Добавить Preset"
            actionThree={openModalPreset}
            titleActionButtonFive="Агрегатор Почт"
            actionFive={handleOpenAggregator}
        >
            <div className="Table">
                <div className="Table-head">
                    <div className="Table-head-item">HP</div>
                    <div className="Table-head-item">IP</div>
                    <div className="Table-head-item">Start date</div>
                    <div className="Table-head-item">Expiration date</div>
                    <div className="Table-head-item">Update date</div>
                    <div className="Table-head-item">GEO</div>

                    <div className="Table-head-item ml-auto  justify-content-end">Actions</div>
                </div>
                <div className="Table-body">
                    {servers.data?.length !== 0 ? (
                        servers.data.map((item) => (
                            <div className="Table-body-row" key={item.id}>
                                <div className="Table-body-row-item">{item.host_provider_name}</div>
                                <div className="Table-body-row-item">{item.ip}</div>
                                <div className="Table-body-row-item">{formatDate(item.start_date)}</div>
                                <div className="Table-body-row-item">{formatDate(item.expiration_date)}</div>
                                <div className="Table-body-row-item">{formatDate(item.updated_at)}</div>
                                <div className="Table-body-row-item">{item.geolocation}</div>
                                <div className="Table-body-row-item ml-auto justify-content-end">
                                    <div>
                                        <Button blue title="More" onClick={() => handleClickMore(item.id)} />
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="Table-no-content">No data available yet</div>
                    )}
                </div>
                <div className="Table-footer">
                    <div className="Table-pagination">
                        {servers.meta?.total > servers.meta?.per_page && (
                            <ReactPaginate
                                previousLabel="<"
                                nextLabel=">"
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                pageCount={servers.meta?.total / servers.meta?.per_page}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={1}
                                onPageChange={changePage}
                                containerClassName="pagination"
                                activeClassName="active"
                                forcePage={page - 1}
                            />
                        )}
                    </div>
                </div>
            </div>
            <ModalCreateServer show={isShow} closeModal={closeOpenModal} loadServer={loadData} />
            {isShowGeneral && <ModalCreateGeneralDomain show={isShowGeneral} closeModal={closeOpenModalGeneral} />}
            {isShowPreset && <ModalCreatePreset show={isShowPreset} closeModal={closeModalPreset} />}
        </UICardContainer>
    );
};

export default PanelSysAdmin;
