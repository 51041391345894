import { Card, Col, Row, Spinner } from 'react-bootstrap';
import './Form.scss';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ReactComponent as Save } from '../../../../assets/images/save.svg';
import { ReactComponent as Add } from '../../../../assets/images/add.svg';
import { createTemplate, editTemplate, getStaticLandingTemplate, getTemplateByUserAndTemplateId } from '../../actions';
import RulesBinom from '../../../../components/Binom/RulesBinom';
import { NotificationManager } from 'react-notifications';
import { useNavigate, useParams } from 'react-router-dom';
import PageFilter from './../../PageFilter';
import ModalCreatePage from '../../../../components/Modals/ModalCreatePage';
import { vaultService } from '../../../../core/services/vault-service';
import UIFormInput from '../../../../components/UI/UIFormInput';

const EventTemplate = () => {
    const defaultOurCloak = {
        active: 1,
        default: 1,
        filters: null,
        id: null,
        name: 'Default',
        position: 0,
        result_page: null,
        result_split: 0,
        rules: null,
        target: 0,
        type: 4,
    };

    const [isLoadingSaving, setIsLoadingSaving] = useState(null);
    const [pages, setPages] = useState([]);
    const [ourCloak, setOurCloak] = useState([defaultOurCloak]);
    const [blocks, setBlocks] = useState([]);
    const [optionsLandings, setOptionsLandings] = useState([]);
    const [isShowModalPage, setIsShowModalPage] = useState(false);
    const [currentPage, setCurrentPage] = useState(null);
    const [name, setName] = useState('');
    const [edit, setEdit] = useState(null);

    const service = vaultService();

    const userId = service.getItem('user').id;

    const openModalPage = (page) => {
        if (typeof page === 'object') {
            setCurrentPage(page);
        }
        setIsShowModalPage(true);
    };

    const onCloseModalPage = () => {
        setCurrentPage(null);
        setIsShowModalPage(false);
    };

    const loadDataStatic = async () => {
        const resp = await dispatch(getStaticLandingTemplate());
        const resultArray = Object.entries(resp).map(([key, value]) => ({
            label: key,
            value: value,
        }));

        setOptionsLandings(resultArray);
    };

    const { id } = useParams();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const loadData = async () => {
        const resp = await dispatch(getTemplateByUserAndTemplateId(userId, id));
        const template = resp.data;
        if (template) {
            const editPages = template?.pages.flat();
            const editCloak = template?.cloak_rules;
            setName(template?.template?.name);
            setPages(editPages);
            setBlocks(template?.blocks_rules);
            setOurCloak(editCloak);
            setEdit(template);
        }
    };

    useEffect(() => {
        loadDataStatic();

        if (id) {
            loadData();
        }
    }, []);

    const hasInvalidData = (rules) => {
        const checkResultPage = (resultPage) => {
            return resultPage.some((element) => element.data.length === 0 || element.data === 'http://');
        };

        const processRules = (rules) => {
            for (let item of rules) {
                if (item.result_page && checkResultPage(item.result_page)) {
                    return true;
                }

                if (item.rules && processRules(item.rules)) {
                    return true;
                }
            }
            return false;
        };

        return processRules(rules);
    };

    const processRules = (rules) => {
        return rules.map((item, index) => {
            const rule = {
                active: item.active,
                name: item.name,
                default: item.default,
                result: item.result,
                position: index + 1,
                result_split: item.result_split,
                target: item.target,
                filters: item.filters?.map((el, indexFilter) => {
                    const filter = {
                        active: el.active,
                        get_data_method_id: el.filter?.id,
                        operator_method_id: el.operator.id,
                        value: el.value,
                        position: indexFilter + 1,
                    };
                    if (el.id && typeof el.id === 'number') {
                        filter.id = el.id;
                    }
                    return filter;
                }),
            };

            if (item.result_page) {
                rule.result_page = item.result_page.map((element, indexLand) => ({
                    ...element,
                    position: indexLand + 1,
                }));
            }

            if (item.rules) {
                rule.rules = processRules(item.rules);
            }

            if (item.id) {
                rule.id = item.id;
            }

            return rule;
        });
    };

    const saveRules = async (type) => {
        setIsLoadingSaving(type);
        const updatePagesRules = pages.map((page) => {
            const rules = page.rules;
            if (rules.length === 0) return;
            const findedErrors = rules.find((item) => !item.filters || item.filters.length === 0);
            const hasError = hasInvalidData(rules);
            if (findedErrors) {
                NotificationManager.error(`В Rules должно быть как минимум одна Filter и один Landing`);
                setIsLoadingSaving(null);
                return;
            }

            if (hasError) {
                NotificationManager.error(`Название Landing не может быть http:// или пустое значение `);
                setIsLoadingSaving(null);
                return;
            }
            const options = {
                page: {
                    name: page?.page.name,
                    description: page?.page?.description,
                    id: typeof page?.page?.id === 'number' ? page?.page?.id : null,
                },
                rules: processRules(rules),
            };

            return options;
        });

        const [cloak] = processRules(ourCloak);
        const optionsCloak = {
            filters: cloak?.filters,
            id: null,
            active: cloak?.active,
        };

        const options = {
            name: name,
            general_rules: updatePagesRules,
            cloak_rules: optionsCloak,
            blocks_rules: { rules: processRules(blocks) },
        };

        if (id) {
            const resp = await dispatch(editTemplate(options, userId, id));

            if (resp) {
                navigate(-1);
            }
        } else {
            const resp = await dispatch(createTemplate(options, userId));

            if (resp) {
                navigate(-1);
            }
        }

        setIsLoadingSaving(null);
    };

    const handleSetRules = (indexPage, newSubRules) => {
        setPages((prevPages) =>
            prevPages.map((page, idxPage) => {
                if (idxPage === indexPage) {
                    return { ...page, rules: newSubRules };
                }
                return page;
            }),
        );
    };

    const handleDeletePage = async (id) => {
        const filterPages = pages.filter((item) => item.page?.id !== id);
        setPages(filterPages);
    };

    const createPages = (page) => {
        setPages([...pages, page]);
    };

    const editPages = (page) => {
        const mapped = pages.map((item) => {
            if (page?.page?.id === item?.page?.id) {
                return page;
            }

            return item;
        });

        setPages(mapped);
    };

    const handleChangeInput = (e) => {
        const { value } = e.target;
        setName(value);
    };

    return (
        <Card className="wrapper p-0">
            <Card.Header
                className="pt-0 pb-0 d-flex align-items-center justify-content-between"
                style={{ height: '60px', paddingLeft: '30px', paddingRight: '30px' }}
            >
                <div className="d-flex gap-2 justify-content-between w-100 align-items-center">
                    <div className="d-flex align-items-center gap-2">
                        <button onClick={() => navigate(-1)} className="wrapper-button-added-path-button">
                            <span>Back</span>
                        </button>
                        <h5 className="m-0">{id ? 'Edit' : 'Create'} Template</h5>
                    </div>
                    <div className="d-flex gap-2 align-items-center">
                        {edit?.template?.general !== 1 && (
                            <button
                                onClick={() => saveRules('save')}
                                style={{ width: '82px' }}
                                className="wrapper-button-added-path-button"
                            >
                                {isLoadingSaving === 'save' ? (
                                    <Spinner animation="border" size="sm" />
                                ) : (
                                    <>
                                        {' '}
                                        <span>Save</span>
                                        <Save />
                                    </>
                                )}
                            </button>
                        )}
                    </div>
                </div>
            </Card.Header>
            <Card.Body className="pl-0 pr-0">
                <div className="CreateTemplate">
                    <Row className="CreateTemplate-input">
                        <Col>
                            <UIFormInput
                                onChange={handleChangeInput}
                                type="text"
                                id="name"
                                placeholder={'Name template'}
                                value={name}
                            />
                        </Col>
                    </Row>
                    <div className="Right-form">
                        <RulesBinom rules={ourCloak} setRules={setOurCloak} ourСloak={true} optionsLandings={optionsLandings} />
                        <div className="Page">
                            <div className="Page-header">Pages</div>
                            {pages.map((page, indexPage) => (
                                <PageFilter
                                    key={page.page.id}
                                    page={page}
                                    setRules={(newSubRules) => handleSetRules(indexPage, newSubRules)}
                                    optionsLandings={optionsLandings}
                                    handleDeletePage={() => handleDeletePage(page?.page.id)}
                                    handleOpenModal={() => openModalPage(page)}
                                />
                            ))}
                            <button onClick={() => openModalPage()} className="Page-button">
                                <span>Page</span>
                                <Add />
                            </button>
                        </div>
                        <RulesBinom rules={blocks} setRules={setBlocks} blocks={true} optionsLandings={optionsLandings} />
                    </div>
                </div>
                {isShowModalPage && (
                    <ModalCreatePage
                        editPages={editPages}
                        show={isShowModalPage}
                        closeModal={onCloseModalPage}
                        page={currentPage}
                        createPages={createPages}
                        create
                    />
                )}
            </Card.Body>
        </Card>
    );
};

export default EventTemplate;
