import React from 'react';
import RulesBinom from '../../../components/Binom/RulesBinom';

const PageFilter = ({ page, optionsLandings = [], setRules, handleDeletePage, handleOpenModal }) => {
    return (
        <RulesBinom
            pageId={page?.page?.id}
            rules={page?.rules}
            pageName={`${page?.page?.name} - ${page?.page?.description}`}
            setRules={setRules}
            optionsLandings={optionsLandings}
            handleDeletePage={handleDeletePage}
            handleOpenModal={handleOpenModal}
        />
    );
};

export default PageFilter;
