import './TemplateList.scss';
import '../../../../assets/styles/table.scss';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as Edit } from '../../../../assets/images/newIcon/edit.svg';
import { ReactComponent as Delete } from '../../../../assets/images/newIcon/delete.svg';
import { ReactComponent as Show } from '../../../../assets/images/newIcon/show.svg';
import { vaultService } from '../../../../core/services/vault-service';
import { useNavigate } from 'react-router-dom';
import UIFormInput from '../../../../components/UI/UIFormInput';
import ModalAccept from '../../../../components/Modals/ModalAccept';
import { deleteTemplate, getTemplates } from '../../../Filters/actions';
import Button from '../../../../components/UI/Button';

const TemplateList = () => {
    const [search, setSearch] = useState('');
    const templates = useSelector((state) => state.buyerReducer.templates);
    const timeoutRef = useRef(null);
    const [isShowAccept, setIsShowAccept] = useState(false);
    const [currentTemplate, setCurrentTemplate] = useState(null);
    const [isLoadingDelete, setIsLoadingDelete] = useState(null);

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const service = vaultService();

    const userId = service.getItem('user').id;

    const loadData = async () => {
        await dispatch(getTemplates(search));
    };

    useEffect(() => {
        loadData();
    }, []);

    const handleClickCreateTemplate = () => {
        navigate(`/filters-templates/create`);
    };

    const handleClickStats = (template) => {
        navigate(`/filters-templates/edit/${template}`);
    };

    const openModal = (template) => {
        setIsShowAccept(true);
        setCurrentTemplate(template);
    };

    const closeModal = () => {
        setIsShowAccept(false);
        setCurrentTemplate(null);
    };

    const handleChangeInput = (e) => {
        const { value } = e.target;
        setSearch(value);

        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(async () => {
            await dispatch(getTemplates(value));
        }, 300);
    };

    const handleDeleteDomain = async () => {
        setIsLoadingDelete(currentTemplate);
        await dispatch(deleteTemplate(userId, currentTemplate));
        closeModal();
        setIsLoadingDelete(null);
        setCurrentTemplate(null);
    };

    const showGeneral = (general) => {
        switch (general) {
            case 0:
                return 'Personal';
            case 1:
                return 'General';
        }
    };

    return (
        <div className="TemplateListWebMaster">
            <div className="TemplateListWebMaster-filters">
                <UIFormInput
                    label=""
                    onChange={handleChangeInput}
                    type="text"
                    id="search"
                    className="TemplateListWebMaster-filters-input"
                    placeholder="Search template"
                    value={search}
                />
                <div className="TemplateList-header-buttons">
                    <Button title="Create template" onClick={handleClickCreateTemplate} blue />
                </div>
            </div>
            <div className="Table">
                <div className="Table-head">
                    <div className="Table-head-item">Name</div>
                    <div className="Table-head-item">Type</div>
                    <div className="Table-head-item ml-auto  justify-content-end">Actions</div>
                </div>
                <div className="Table-body">
                    {templates.data?.length !== 0 ? (
                        templates.data.map((item) => (
                            <div className="Table-body-row" key={item.template.id}>
                                <div className="Table-body-row-item">{item.template.name}</div>
                                <div className="Table-body-row-item">
                                    <div className="TemplateListWebMaster-type">{showGeneral(item.template?.general)}</div>
                                </div>
                                <div className="Table-body-row-item ml-auto justify-content-end">
                                    {item?.template?.general === 1 && (
                                        <Edit
                                            onClick={() => handleClickStats(item.template.id)}
                                            className="TemplateListWebMaster-icon"
                                        />
                                    )}
                                    {item?.template?.general === 1 && (
                                        <Delete
                                            onClick={() => openModal(item.template.id)}
                                            className="TemplateListWebMaster-icon"
                                        />
                                    )}
                                    {item?.template?.general === 0 && (
                                        <Show
                                            onClick={() => handleClickStats(item.template.id)}
                                            className="TemplateListWebMaster-icon"
                                        />
                                    )}
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="Table-no-content">No data available yet</div>
                    )}
                </div>
                <div className="Table-footer"></div>
            </div>
            {/*<div className="pagination">*/}
            {/*    {templates.total > 15 && (*/}
            {/*        <ReactPaginate*/}
            {/*            previousLabel="<"*/}
            {/*            nextLabel=">"*/}
            {/*            pageClassName="page-item"*/}
            {/*            pageLinkClassName="page-link"*/}
            {/*            previousClassName="page-item"*/}
            {/*            previousLinkClassName="page-link"*/}
            {/*            nextClassName="page-item"*/}
            {/*            nextLinkClassName="page-link"*/}
            {/*            breakLabel="..."*/}
            {/*            breakClassName="page-item"*/}
            {/*            breakLinkClassName="page-link"*/}
            {/*            pageCount={domains.total / 15}*/}
            {/*            marginPagesDisplayed={2}*/}
            {/*            pageRangeDisplayed={1}*/}
            {/*            onPageChange={changePage}*/}
            {/*            containerClassName="pagination"*/}
            {/*            activeClassName="active"*/}
            {/*            forcePage={page - 1}*/}
            {/*        />*/}
            {/*    )}*/}
            {/*</div>*/}
            {isShowAccept && (
                <ModalAccept
                    isLoading={isLoadingDelete === currentTemplate}
                    show={isShowAccept}
                    title={`Do you wand delete template ?`}
                    closeModal={closeModal}
                    regenerate={handleDeleteDomain}
                />
            )}
        </div>
    );
};

export default TemplateList;
