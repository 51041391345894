import { Button, Col, Modal, Row, Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import SelectSearch from '../../UI/Select';
import { getTemplatesByUserId } from '../../../pages/Filters/actions';
import { vaultService } from '../../../core/services/vault-service';

const ModalTemplates = ({ closeModal, show, blocks, ourCloak, pages, setPages, setBlocks, setOurCloak, createNewPage }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [templates, setTemplates] = useState([]);
    const [selectedTemplates, setSelectedTemplates] = useState(null);

    const service = vaultService();
    const userId = service.getItem('user').id;

    const dispatch = useDispatch();

    const showGeneral = (general) => {
        switch (general) {
            case 0:
                return 'Personal';
            case 1:
                return 'General';
        }
    };

    const loadTemplates = async () => {
        const resp = await dispatch(getTemplatesByUserId(userId));
        const optionsTemplates = resp.data.map((item) => ({
            ...item,
            label: `${item.template.name} - ${showGeneral(item.template.general)}`,
            value: item.template.id,
        }));
        setTemplates(optionsTemplates);
    };

    useEffect(() => {
        loadTemplates();
    }, []);

    const closedModal = () => {
        closeModal();
    };

    const isDisableButton = () => {
        if (isLoading) return true;
        return false;
    };

    const handleChangeSelect = (value) => {
        setSelectedTemplates(value);
    };

    const handleClickApply = async () => {
        setIsLoading(true);
        const currentTemplate = templates.find((item) => item.template.id === selectedTemplates);

        if (currentTemplate) {
            const mutateOurCloak = ourCloak.map((item) => ({
                ...item,
                filters: [...item.filters, ...currentTemplate?.cloak_rules[0]?.filters],
            }));
            const templatePages = currentTemplate.pages.flat();
            const newPages = templatePages.filter((item) => !pages.some((element) => element.page.name === item.page.name));
            let addedNewPages = [];
            if (newPages.length !== 0) {
                const createdPages = newPages.map(async (item) => {
                    const options = {
                        name: item.page?.name,
                        description: item?.page?.description,
                    };
                    const resp = await createNewPage(options);
                    return resp;
                });

                const resp = await Promise.all(createdPages);

                addedNewPages = resp.filter((item) => item !== null);
            }

            const mutatePages = [...pages, ...addedNewPages].map((item) => {
                const findNamePage = templatePages.find((element) => element?.page?.name === item?.page?.name);

                if (findNamePage) {
                    return {
                        ...item,
                        rules: [...item.rules, ...findNamePage.rules.map((element) => ({ ...element, id: null }))],
                    };
                }

                return item;
            });

            setBlocks([...blocks, ...currentTemplate?.blocks_rules.map((item) => ({ ...item, id: null }))]);
            setOurCloak(mutateOurCloak);
            setPages(mutatePages);
        }
        closedModal();
        setIsLoading(false);
    };

    return (
        <Modal show={show} onHide={closedModal} className="modal-gd">
            <Modal.Header closeButton>
                <Modal.Title>Templates</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <Row className="d-flex flex-column">
                        <Col xs={12}>
                            <SelectSearch
                                placeholder="Templates"
                                onChange={handleChangeSelect}
                                title={''}
                                options={templates}
                                itemLabel={'label'}
                                itemValue={'value'}
                                default={selectedTemplates}
                                value={selectedTemplates}
                            />
                        </Col>
                    </Row>
                    <div className="CreateDomain-buttons">
                        <Button
                            style={{ background: 'silver' }}
                            className="wrapper-button-added-path-button"
                            variant="secondary"
                            onClick={closedModal}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="wrapper-button-added-path-button"
                            disabled={isDisableButton()}
                            variant="primary"
                            onClick={handleClickApply}
                        >
                            {isLoading ? <Spinner size="sm" animation="border" /> : 'Apply'}
                        </Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ModalTemplates;
