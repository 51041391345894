import { Col, Row } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import './styles.scss';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTopic } from '../../SysAdmin/action';
import { changeOrderPages, getOrders } from '../../WebMaster/actions';
import { vaultService } from '../../../core/services/vault-service';
import Button from '../../../components/UI/Button';

const ListOrder = () => {
    const orders = useSelector((state) => state.webMasterReducer.orders);
    const page = useSelector((state) => state.webMasterReducer.pageOrder);
    const topics = useSelector((state) => state.serverReducer.topics);

    const dispatch = useDispatch();

    const service = vaultService();

    const userId = service.getItem('user').id;

    const changePage = async (page) => {
        const currentPage = page.selected + 1;
        dispatch(changeOrderPages(currentPage));
        await dispatch(getOrders(currentPage, userId));
    };

    const loadData = async () => {
        await dispatch(getOrders(page, userId));
        await dispatch(getTopic());
    };

    const showNameTopic = (id) => {
        const topic = topics.find((item) => item.id === id);
        if (topic) {
            return topic.topic;
        }

        return '-';
    };

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div className="ListOrderBuyer">
            <div className="Table">
                <div className="Table-head">
                    <div className="Table-head-item  ListOrderBuyer-item">Name</div>
                    <div className="Table-head-item   ListOrderBuyer-item">Topic</div>
                    <div className="Table-head-item ListOrderBuyer-item">Comment</div>
                    <div className="Table-head-item ListOrderBuyer-item">Status</div>
                </div>
                <div className="Table-body">
                    {orders.data?.length !== 0 ? (
                        orders.data.map((item) => (
                            <div className="Table-body-row" key={item.id}>
                                <div className="Table-body-row-item  ListOrderBuyer-item">{item.domain_name}</div>
                                <div className="Table-body-row-item  ListOrderBuyer-item">{showNameTopic(item.topic_id)}</div>
                                <div className="Table-body-row-item ListOrderBuyer-item">{item.desc}</div>
                                <div className="Table-body-row-item ListOrderBuyer-item">{item.status}</div>
                            </div>
                        ))
                    ) : (
                        <div className="Table-no-content">No data available yet</div>
                    )}
                </div>
                <div className="Table-footer">
                    <div className="Table-pagination">
                        {orders.meta?.total > orders.meta?.per_page && (
                            <ReactPaginate
                                previousLabel="<"
                                nextLabel=">"
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                pageCount={orders.meta?.total / orders.meta?.per_page}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={1}
                                onPageChange={changePage}
                                containerClassName="pagination"
                                activeClassName="active"
                                forcePage={page - 1}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ListOrder;
