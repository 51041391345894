/* eslint-disable */

import {
    SET_TEMPLATE,
    SET_DOMAIN_BUYER,
    CHANGE_PAGE,
    CHANGE_PAGE_RESERVED,
    SET_DOMAIN_BUYER_RESERVED,
    CHANGE_TAB_BUYER,
    SET_DOMAIN_STATS,
    CHANGE_PAGE_STATS,
    DELETE_TEMPLATE,
} from './types';

const initialState = {
    domains: {
        data: [],
        meta: {
            per_page: 15,
            last_page: 1,
            total: 1,
            current_page: 1,
        },
    },
    domainsStats: {
        domains: [],
        total: 0,
    },
    domainsReserved: {
        data: [],
        meta: {
            per_page: 15,
            last_page: 1,
            total: 1,
            current_page: 1,
        },
    },
    templates: {
        data: [],
    },
    page: 1,
    pageReserved: 1,
    pageStats: 1,
    tab: 1,
};

const buyerReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case DELETE_TEMPLATE:
            return {
                ...state,
                templates: { ...state.templates, data: state.templates.data.filter((item) => item.template.id !== payload) },
            };
        case SET_TEMPLATE:
            return { ...state, templates: payload };
        case SET_DOMAIN_BUYER:
            return { ...state, domains: payload };
        case SET_DOMAIN_STATS:
            return { ...state, domainsStats: payload };
        case SET_DOMAIN_BUYER_RESERVED:
            return { ...state, domainsReserved: payload };
        case CHANGE_PAGE:
            return { ...state, page: payload };
        case CHANGE_PAGE_STATS:
            return { ...state, page: payload };
        case CHANGE_TAB_BUYER:
            return { ...state, tab: payload };
        case CHANGE_PAGE_RESERVED:
            return { ...state, pageReserved: payload };
        default:
            return state;
    }
};

export default buyerReducer;
