import { useDispatch, useSelector } from 'react-redux';
import UICardContainer from '../../../components/UI/UICardContainer';
import './styles.scss';
import ReactPaginate from 'react-paginate';
import { Col, Row } from 'react-bootstrap';
import { changePages, deactivatedRole, deactivatedUser, getRoles, getUsers } from '../actions';
import { useEffect, useState } from 'react';
import Button from '../../../components/UI/Button';
import ModalActivatedUser from '../../../components/Modals/ModalActivatedUser';

const ListUsers = () => {
    const users = useSelector((state) => state.adminReducer.users);
    const page = useSelector((state) => state.adminReducer.page);
    const roles = useSelector((state) => state.adminReducer.roles);

    const [isShow, setIsShow] = useState(false);
    const [activeUser, setActiveUser] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();

    const changePage = async (page) => {
        const currentPage = page.selected + 1;
        dispatch(changePages(currentPage));
        await dispatch(getUsers(currentPage));
    };

    const loadData = async () => {
        await dispatch(getUsers(page));
        await dispatch(getRoles());
    };

    useEffect(() => {
        loadData();
    }, []);

    const openModal = (id) => {
        setIsShow(true);
        setActiveUser(id);
    };

    const closeModal = () => {
        setIsShow(false);
        setActiveUser(null);
    };

    const handleClickDeactivateUser = async (user) => {
        setIsLoading(true);
        const role = roles.filter((item) => user.roles.some((el) => item.name === el));

        await role.map(async (item) => {
            await dispatch(deactivatedRole(user.id, item.id));
        });

        await dispatch(deactivatedUser(user.id));
        loadData();
        setIsLoading(false);
    };

    return (
        <div className="ListUsers">
            <div className="ListUsers-header">
                <div className="ListUsers-header-title">Список пользователей</div>
            </div>
            <div className="Table">
                <div className="Table-head">
                    <div className="Table-head-item">Name</div>
                    <div className="Table-head-item">Email</div>
                    <div className="Table-head-item">Role</div>
                    <div className="Table-head-item ml-auto  justify-content-end">Actions</div>
                </div>
                <div className="Table-body">
                    {users.data?.length !== 0 ? (
                        users.data.map((item) => (
                            <div className="Table-body-row" key={item.id}>
                                <div className="Table-body-row-item">{item.name}</div>
                                <div className="Table-body-row-item">{item.email}</div>
                                <div className="Table-body-row-item">{item.roles[item.roles.length - 1]}</div>
                                <div className="Table-body-row-item ml-auto justify-content-end">
                                    {item.status === 'active' ? (
                                        <Button
                                            title="Деактивировать"
                                            isLoading={isLoading}
                                            onClick={() => handleClickDeactivateUser(item)}
                                        />
                                    ) : (
                                        <Button blue title="Активировать" onClick={() => openModal(item)} />
                                    )}
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="Table-no-content">No data available yet</div>
                    )}
                </div>
                <div className="Table-footer">
                    <div className="Table-pagination">
                        {users.meta?.total > users.meta?.per_page && (
                            <ReactPaginate
                                previousLabel="<"
                                nextLabel=">"
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                pageCount={users.meta?.total / users.meta?.per_page}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={1}
                                onPageChange={changePage}
                                containerClassName="pagination"
                                activeClassName="active"
                                forcePage={page - 1}
                            />
                        )}
                    </div>
                </div>
            </div>
            {isShow && <ModalActivatedUser show={isShow} closeModal={closeModal} loadData={loadData} user={activeUser} />}
        </div>

        // </UICardContainer>
    );
};

export default ListUsers;
