import { Button, Col, Modal, Row, Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import UIFormInput from '../../UI/UIFormInput';
import { createPage, editPage } from '../../../pages/Filters/actions';
import { nanoid } from 'nanoid';

const ModalCreatePage = ({ closeModal, show, domainId, page, loadData, create, editPages, createPages }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [build, setBuild] = useState({
        name: '',
        description: '',
    });

    const dispatch = useDispatch();

    useEffect(() => {
        if (page) {
            if (create) {
                setBuild({
                    name: page?.page?.name,
                    description: page?.page?.description,
                });
            } else {
                setBuild({
                    name: page?.name,
                    description: page?.description,
                });
            }
        }
    }, []);

    const closedModal = () => {
        closeModal();
        setBuild({
            name: '',
            description: '',
        });
    };

    const handleClickButton = async () => {
        setIsLoading(true);

        if (create) {
            if (page) {
                const newPage = { page: { ...build, id: page?.page?.id }, rules: page?.rules };
                editPages(newPage);
            } else {
                const newPage = { page: { ...build, id: nanoid() }, rules: [] };
                createPages(newPage);
            }

            setIsLoading(false);
            closedModal();
            return;
        }

        if (page) {
            const resp = await dispatch(editPage(domainId, page?.id, build));
            if (resp) {
                await loadData();
                closedModal();
            }
        } else {
            const resp = await dispatch(createPage(domainId, build));
            if (resp) {
                await loadData();
                closedModal();
            }
        }

        setIsLoading(false);
    };

    const handleChangeInput = (e) => {
        const value = e.target.value;
        const key = e.target.id;
        setBuild({ ...build, [key]: value });
    };

    const isDisableButton = () => {
        if (isLoading) return true;
        if (build.name?.length === 0) return true;
        if (build.description?.length === 0) return true;
        return false;
    };

    return (
        <Modal show={show} onHide={closedModal} className="modal-gd">
            <Modal.Header closeButton>
                <Modal.Title>{page ? 'Edit' : 'Create'} page</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <Row className="d-flex flex-column">
                        <Col xs={12}>
                            <UIFormInput
                                onChange={handleChangeInput}
                                type="text"
                                id="name"
                                label={'Name'}
                                value={build?.name ? build?.name : ''}
                            />
                        </Col>
                        <Col xs={12}>
                            <UIFormInput
                                onChange={handleChangeInput}
                                type="text"
                                id="description"
                                label={'Description'}
                                value={build?.description ? build?.description : ''}
                            />
                        </Col>
                    </Row>
                    <div className="CreateDomain-buttons">
                        <Button
                            style={{ background: 'silver' }}
                            className="wrapper-button-added-path-button"
                            variant="secondary"
                            onClick={closedModal}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="wrapper-button-added-path-button"
                            disabled={isDisableButton()}
                            variant="primary"
                            onClick={handleClickButton}
                        >
                            {isLoading ? <Spinner size="sm" animation="border" /> : page ? 'Edit' : 'Create'}
                        </Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ModalCreatePage;
