import { Button, Col, Row } from 'react-bootstrap';
import './MessageList.scss';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { vaultService } from '../../../core/services/vault-service';
import { changePagesMessage, getAccountList, getMessageList } from '../actions';
import DatePicker from 'react-datepicker';
import moment from 'moment/moment';
import UIFormInput from '../../../components/UI/UIFormInput';
import SelectMulti from '../../../components/UI/SelectMulti';

const MessageList = () => {
    const { messageList, pageMessage, accountList, pageAccount } = useSelector((state) => state.aggregatorReducer);
    const [search, setSearch] = useState('');
    const [filtersMessage, setFilterMessage] = useState({
        account: [],
        date: [],
    });
    const [startDate, endDate] = filtersMessage.date;
    const timeoutRef = useRef(null);

    const dispatch = useDispatch();

    const service = vaultService();

    const filtersLocalStorage = service.getItem('filtersLocalStorage');

    const optionsAccounts = accountList.data.map((item) => ({ label: item.email, value: item.id }));

    const loadData = async () => {
        if (filtersLocalStorage) {
            const isNull = filtersLocalStorage.date.filter((item) => item !== null);
            const filter = {
                ...filtersLocalStorage,
                date: isNull.length !== 0 ? filtersLocalStorage.date.map((item) => new Date(item)) : [],
            };
            await dispatch(getMessageList(pageMessage, filter, search));
            await dispatch(getAccountList(pageAccount));
            setFilterMessage(filter);
            return;
        }
        await dispatch(getMessageList(pageMessage, filtersMessage, search));
        await dispatch(getAccountList(pageAccount));
    };

    const handleChangeAccount = (e) => {
        const filter = { ...filtersMessage, account: e };
        setFilterMessage(filter);
        service.setItem('filtersLocalStorage', filter);
    };

    const handleChangeInput = (e) => {
        const { value } = e.target;
        setSearch(value);

        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(async () => {
            await dispatch(getMessageList(pageMessage, filtersMessage, value));
        }, 700);
    };

    const handleChangeDate = (dates) => {
        const [start, end] = dates;
        const isNull = dates.find((item) => item === null);
        if (isNull) {
            const filter = { ...filtersMessage, date: [] };
            setFilterMessage(filter);
            service.setItem('filtersLocalStorage', filter);
            return;
        }
        const filter = { ...filtersMessage, date: [start, end] };
        setFilterMessage(filter);
        service.setItem('filtersLocalStorage', filter);
    };

    const changePage = async (page) => {
        const currentPage = page.selected + 1;
        dispatch(changePagesMessage(currentPage));
        await dispatch(getMessageList(currentPage));
    };

    const handleClickButton = () => {
        dispatch(getMessageList(pageMessage, filtersMessage, search));
    };

    useEffect(() => {
        loadData();
    }, []);

    const formattedDate = (date) => {
        return moment(date).format('YYYY-MM-DD HH:mm:ss');
    };

    const dateDefault = () => {
        const filter = { ...filtersMessage, date: [] };
        setFilterMessage(filter);
        service.setItem('filtersLocalStorage', filter);
    };

    return (
        <div className="MessageList">
            {/*<div className="MessageList-filters">*/}
            {/*    <div className="p-0">*/}
            {/*        <SelectMulti*/}
            {/*            onChange={handleChangeAccount}*/}
            {/*            options={optionsAccounts}*/}
            {/*            values={filtersMessage.account}*/}
            {/*            placeholder={'Email'}*/}
            {/*            itemLabel={'label'}*/}
            {/*            itemValue={'value'}*/}
            {/*            className="MessageList-filters-select"*/}
            {/*        />*/}
            {/*    </div>*/}
            {/*    <div className="d-flex align-items-center pt-1 position-relative">*/}
            {/*        <span className="button-default-date" onClick={dateDefault}>*/}
            {/*            X*/}
            {/*        </span>*/}
            {/*        <DatePicker*/}
            {/*            maxDate={new Date()}*/}
            {/*            selected={startDate}*/}
            {/*            onChange={handleChangeDate}*/}
            {/*            className="DatePicker-email"*/}
            {/*            startDate={startDate}*/}
            {/*            endDate={endDate}*/}
            {/*            selectsRange*/}
            {/*        />*/}
            {/*    </div>*/}
            {/*    <Button variant="primary" className="mt-1" onClick={handleClickButton}>*/}
            {/*        Применить*/}
            {/*    </Button>*/}
            {/*    <div className="MessageList-filters-search">*/}

            {/*        <UIFormInput*/}
            {/*            onChange={handleChangeInput}*/}
            {/*            type="text"*/}
            {/*            id="name"*/}
            {/*            placeholder="Search message"*/}
            {/*            value={search}*/}
            {/*        />*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className="MessageList-filters">
                <SelectMulti
                    onChange={handleChangeAccount}
                    options={optionsAccounts}
                    values={filtersMessage.account}
                    placeholder={'Email'}
                    itemLabel={'label'}
                    itemValue={'value'}
                    className="MessageList-filters-select"
                />
                <UIFormInput
                    onChange={handleChangeInput}
                    type="text"
                    id="name"
                    placeholder="Search message"
                    className="MessageList-filters-input"
                    value={search}
                />
                <div className="d-flex align-items-center pt-1 position-relative">
                    <span className="button-default-date" onClick={dateDefault}>
                        X
                    </span>
                    <DatePicker
                        maxDate={new Date()}
                        selected={startDate}
                        onChange={handleChangeDate}
                        className="DatePicker-email"
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                    />
                </div>
                <Button variant="primary" className="mt-1" onClick={handleClickButton}>
                    Применить
                </Button>
            </div>

            <div className="Table">
                <div className="Table-head">
                    <div className="Table-head-item MessageList-item">From</div>
                    <div className="Table-head-item  MessageList-item">To</div>
                    <div className="Table-head-item  MessageList-item">Body</div>
                    <div className="Table-head-item  MessageList-item">Subject</div>
                    <div className="Table-head-item  MessageList-item">Date</div>
                </div>
                <div className="Table-body">
                    {messageList.data?.length !== 0 ? (
                        messageList.data.map((item) => (
                            <div className="Table-body-row" key={item.id}>
                                <div className="Table-body-row-item MessageList-item">{item.from}</div>
                                <div className="Table-body-row-item  MessageList-item">{item.to}</div>
                                <div className="Table-body-row-item  MessageList-item">{item.body}</div>
                                <div className="Table-body-row-item  MessageList-item">{item.subject}</div>
                                <div className="Table-body-row-item  MessageList-item">{formattedDate(item.date)}</div>
                            </div>
                        ))
                    ) : (
                        <div className="Table-no-content">No data available yet</div>
                    )}
                </div>
                <div className="Table-footer">
                    <div className="Table-pagination">
                        {messageList.meta?.total > messageList.meta?.per_page && (
                            <ReactPaginate
                                previousLabel="<"
                                nextLabel=">"
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                pageCount={messageList.meta?.total / messageList.meta?.per_page}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={1}
                                onPageChange={changePage}
                                containerClassName="pagination"
                                activeClassName="active"
                                forcePage={pageMessage - 1}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MessageList;
