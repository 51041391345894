import { Col, Row } from 'react-bootstrap';
import React, { useEffect, useRef, useState } from 'react';
import UICardContainer from '../../components/UI/UICardContainer';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '../../core/helpers/routes';
import { useDispatch, useSelector } from 'react-redux';
import { getDomeins } from './actions';
import './styles.scss';
import ReactPaginate from 'react-paginate';
import EditButton from '../../components/UI/EditButton';
import ModalCreateDomain from '../../components/Modals/ModalCreateDomain';
import { getDomainCheck } from '../SysAdmin/action';
import UIFormInput from '../../components/UI/UIFormInput';
import Button from '../../components/UI/Button';

const Domeins = () => {
    const servers = useSelector((state) => state.serverReducer.servers);
    const [isShow, setIsShow] = useState(false);
    const [checks, setChecks] = useState([]);
    const [edit, setEdit] = useState(null);
    const domeins = useSelector((state) => state.domeinsReducer.domeins);
    const [page, setPage] = useState(1);
    const { id } = useParams();
    const [name, setName] = useState('');
    const timeoutRef = useRef(null);

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const handleClickEditDomain = (item) => {
        console.log('dasdass');
        setEdit(item);
        openModal();
    };

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        await dispatch(getDomeins(page, id, name));
        const check = await dispatch(getDomainCheck());
        setChecks(check);
    };

    const changePage = async (page) => {
        const currentPage = page.selected + 1;
        await setPage(currentPage);
        dispatch(getDomeins(currentPage, id, name));
    };

    const openModal = () => {
        setIsShow(true);
    };

    const closeModal = () => {
        setEdit(null);
        setIsShow(false);
    };

    const showNameServer = () => {
        const server = servers.data.find((item) => item.id === Number(id));
        if (server) return ` - ${server.host_provider_name}`;
        return '';
    };

    const showStatusDomain = (domain) => {
        const check = checks.find((item) => item.name === domain.name);

        switch (check?.status) {
            case 'up':
                return <div className="status-up-buyer" />;
            case 'down':
                return <div className="status-down-buyer" />;
            case 'unknown':
                return <div className="status-unknown-buyer" />;
            default:
                return <div className="status-none-buyer" />;
        }
    };

    const handleChangeInput = (e) => {
        const { value } = e.target;
        setName(value);

        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(async () => {
            await dispatch(getDomeins(page, id, value));
        }, 300);
    };

    return (
        <div className="DomainListServer">
            <div className="DomainListServer-header">
                <div className="DomainListServer-header-back-button">
                    <Button title="Back" onClick={() => navigate(-1)} />
                </div>
                <div className="DomainListServer-header-title">Список доменов {showNameServer()}</div>
                <div className="DomainListServer-header-buttons">
                    <Button title="Добавить Domain" onClick={openModal} blue />
                </div>
            </div>
            <div className="DomainListServer-filters">
                <UIFormInput
                    label=""
                    onChange={handleChangeInput}
                    type="text"
                    id="name"
                    className="DomainListServer-filters-input"
                    placeholder="Search template"
                    value={name}
                />
            </div>
            <div className="Table">
                <div className="Table-head">
                    <div className="Table-head-item DomainListServer-item">Name</div>
                    <div className="Table-head-item DomainListServer-item">Theme</div>
                    <div className="Table-head-item DomainListServer-item">Registrar</div>
                    <div className="Table-head-item DomainListServer-item">Status</div>
                    <div className="Table-head-item DomainListServer-item ml-auto  justify-content-end">Actions</div>
                </div>
                <div className="Table-body">
                    {domeins.data?.length !== 0 ? (
                        domeins.data.map((item) => (
                            <div className="Table-body-row" key={item.id}>
                                <div className="Table-body-row-item DomainListServer-item">
                                    {showStatusDomain(item)}
                                    {item.name}
                                </div>
                                <div className="Table-body-row-item DomainListServer-item">{item['topic'].topic}</div>
                                <div className="Table-body-row-item DomainListServer-item">{item?.registrar}</div>
                                <div className="Table-body-row-item DomainListServer-item">{item?.status}</div>

                                <div className="Table-body-row-item DomainListServer-item ml-auto justify-content-end">
                                    <div className="edit-button" onClick={() => handleClickEditDomain(item)}>
                                        <EditButton />
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="Table-no-content">No data available yet</div>
                    )}
                </div>
                <div className="Table-footer">
                    <div className="Table-pagination">
                        {domeins.meta?.total > domeins.meta?.per_page && (
                            <ReactPaginate
                                previousLabel="<"
                                nextLabel=">"
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                pageCount={domeins.meta?.total / domeins.meta?.per_page}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={1}
                                onPageChange={changePage}
                                containerClassName="pagination"
                                activeClassName="active"
                                forcePage={page - 1}
                            />
                        )}
                    </div>
                </div>
            </div>
            {isShow && <ModalCreateDomain show={isShow} closeModal={closeModal} loadDomain={loadData} edit={edit} />}
        </div>

        // <UICardContainer
        //     title={`Список доменов${showNameServer()}`}
        //     backLink={ROUTES.sysAdminPanel.path}
        //     titleActionButton="Добавить Domain"
        //     action={openModal}
        // >
        //     <div className="d-flex flex-column">
        //         <Row xs={9} className="border-bottom pb-3 text-bold position-relative">
        //             <Col className="item_header">Name</Col>
        //             <Col className="item_header">Theme</Col>
        //             <Col className="item_header">Registrar</Col>
        //             <Col className="item_header">Status</Col>
        //             <Col className="item_header"></Col>
        //             <Col className="item_header"></Col>
        //             <Col className="item_header"></Col>
        //             <div className="MyDomain-search">
        //                 <UIFormInput
        //                     onChange={handleChangeInput}
        //                     type="text"
        //                     id="name"
        //                     placeholder="Search domain"
        //                     value={name}
        //                 />
        //             </div>
        //         </Row>
        //
        //         {domeins.data.map((item) => (
        //             <Row xs={9} className={`border-bottom pb-3 pt-4 domains_item position-relative`} key={item.id}>
        //                 <Col className="item position-relative">
        //                     {showStatusDomain(item)}
        //                     {item.name}
        //                 </Col>
        //                 <Col className="item">{item['topic'].topic}</Col>
        //                 <Col className="item">{item?.registrar}</Col>
        //                 <Col className="item">{item.status}</Col>
        //                 <Col className="item"></Col>
        //                 <Col className="item"></Col>
        //                 <Col className="item">
        //                     <div className="edit-button" onClick={() => handleClickEditDomain(item)}>
        //                         <EditButton />
        //                     </div>
        //                 </Col>
        //             </Row>
        //         ))}
        //     </div>
        //     <div className="pagination">
        //         {domeins.meta?.total > domeins.meta?.per_page && (
        //             <ReactPaginate
        //                 previousLabel="<"
        //                 nextLabel=">"
        //                 pageClassName="page-item"
        //                 pageLinkClassName="page-link"
        //                 previousClassName="page-item"
        //                 previousLinkClassName="page-link"
        //                 nextClassName="page-item"
        //                 nextLinkClassName="page-link"
        //                 breakLabel="..."
        //                 breakClassName="page-item"
        //                 breakLinkClassName="page-link"
        //                 pageCount={domeins.meta?.total / domeins.meta?.per_page}
        //                 marginPagesDisplayed={2}
        //                 pageRangeDisplayed={1}
        //                 onPageChange={changePage}
        //                 containerClassName="pagination"
        //                 activeClassName="active"
        //                 forcePage={page - 1}
        //             />
        //         )}
        //     </div>
        // </UICardContainer>
    );
};

export default Domeins;
